<template>
<header id="header" class="header" role="banner">
    <!-- top -->
    <div class="header_top">
        <div class="inner">
            <h1 class="tit_header">
                <a href="/" title="메인으로 이동"><img src="" data-images-path="/image/logo/peoplelife_basic.svg" alt="로고"></a>
            </h1>
            <nav class="gnb"  role="navigation">
                <a class="link_gnb" data-depth="intro">피플라이프</a>
                <a class="link_gnb" data-depth="sales">영업채널</a>
                <a class="link_gnb" data-depth="brand">브랜드</a>
                <a class="link_gnb" data-depth="promotion">홍보센터</a>
                <a class="link_gnb" data-depth="recruit">인재채용</a>
            </nav>
            <div class="btn_drawer">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <!-- bottom -->
    <div class="header_bottom">
        <div class="inner">
            <div class="banner">
                <h3><strong>피플라이프 전문가</strong>가 <br><strong>최적의 솔루션</strong>을 찾아드립니다</h3>
                <ul>
                    <li><a href="/sales/Fa#freeConsult">개인 상담신청<i></i></a></li>
                    <li><a href="/sales/Corporation#freeConsult">법인 상담신청<i></i></a></li>
                </ul>
            </div>
            <nav class="lnb" role="navigation">
                <div class="item_lnb">
                    <a class="link_lnb" href="/intro/Company">회사소개</a>
                    <a class="link_lnb" href="/intro/History">연혁</a>
                    <a class="link_lnb" href="/intro/Network">전문가네트워크</a>
                    <a class="link_lnb" href="/intro/Branch">사업단찾기</a>
                    <a class="link_lnb" href="/intro/Social">사회공헌</a>
                    <a class="link_lnb" href="/intro/Notify">경영공시</a>
                    <a class="link_lnb" href="/intro/Finance">재무현황공시</a>
                </div>
                <div class="item_lnb">
                    <a class="link_lnb" href="/sales/Corporation">법인컨설팅</a>
                    <a class="link_lnb" href="/sales/Fa">개인영업(FA)</a>
                    <a class="link_lnb" href="/sales/Otc">보험클리닉</a>
                    <a class="link_lnb" href="/sales/Tfa">TM영업(TFA)</a>
                </div>
                <div class="item_lnb">
                    <a class="link_lnb" href="/brand/Bohum">보험클리닉</a>
                    <a class="link_lnb" href="/brand/Ceo">CEO클리닉</a>
                </div>
                <div class="item_lnb">
                    <a class="link_lnb" href="/promotion/News">뉴스</a>
                    <a class="link_lnb" href="/promotion/Webzine">웹진</a>
                    <a class="link_lnb" href="/promotion/Promotion">홍보자료</a>
                    <a class="link_lnb" href="/promotion/Inside">인사이드</a>
                </div>
                <div class="item_lnb">
                    <a class="link_lnb" href="/recruit/RecruitInfo">채용정보</a>
                    <a class="link_lnb" href="/recruit/RecruitCorp">법인컨설팅 채용</a>
                    <a class="link_lnb" href="/recruit/RecruitFa">개인영업(FA) 채용</a>
                    <a class="link_lnb" href="/recruit/RecruitBohum">보험클리닉 채용</a>
                </div>
            </nav>
        </div>
    </div>
</header>
</template>



<script>
import $ from 'jquery'

export default { 
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            /* HEADER */
            var $header = $('#header');
            var $gnb = $header.find('.gnb');
            var $linkGnb = $gnb.find('.link_gnb');
            var $container = $('#container');
            var $btnDrawer = $header.find('.btn_drawer');

            // Toggle Event
            var headerToggle = {
                active : function() {
                    if (!$header.hasClass('is-active')) {
                        $header.addClass('is-active');

                        // $header 마우스 토글 버그에 따른 조치
                        $container.on('mouseenter', function(){
                            if ($header.hasClass('is-active')) {
                                $header.removeClass('is-active');
                            }
                        });
                    }
                },
                close : function(){
                    $header.removeClass('is-active');
                }
            }

            // Toggle function
            function headerToggleFnc() {
                if (!$header.hasClass('is-active')) {
                    headerToggle.active();
                } else {
                    headerToggle.close();
                }
            }

            // toggle - Click
            $header.on('click', function(){
                headerToggleFnc();
            });

            // toggle - Mouse Hover
            $header.on('mouseenter', function(){
                headerToggle.active();
                return false;
            }).on('mouseleave', function(){
                headerToggle.close();
                return false;
            });

            // toggle - 햄버거 메뉴
            $btnDrawer.on('click', function(){
                headerToggleFnc();
                return false;
            });

            // LNB
            var $lnb = $header.find('.lnb');
            var $itemLnb = $lnb.find('.item_lnb');

            $itemLnb.each(function(i) {
                var $this = $(this);
                $this.on('mouseenter', function(){
                    $linkGnb.eq(i).addClass('is-active');
                    return false;
                }).on('mouseleave', function(){
                    $linkGnb.removeClass('is-active');
                    return false;
                });
            });

            // scroll action
            var $window = $(window);

            $window.on('scroll', function() {
                var scrolled = $window.scrollTop() >= 100;
                $header.toggleClass('is-scroll', scrolled);
            });

            // main 화면이 아닌 경우
            if(location.pathname !== '/') {
                $header.addClass('is-sub');
            }

            /* 서브depth에서 위치 표시 */
            var pathName = document.location.pathname
            var pathNameArr = pathName.split("/");

            $gnb.find('[data-depth="'+pathNameArr[1]+'"]').addClass('is-active-page');
            $lnb.find('a[href="'+pathName+'"]').addClass('is-active-page');



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>


