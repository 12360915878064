<template>
<!-- #container -->
<div id="container" role="main">

    <!-- 페이지명 -->
    <section id="pageName">
        <div class="inner">
            <h3 class="tit_section">홍보자료</h3>
            <p class="desc_section">피플라이프를 다양한 자료를 만나실 수 있습니다.</p>
        </div>
    </section>
    <!-- 페이지명 -->

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">
                <p><strong>PEOPLELIFE</strong> Room</p>
                <p>고객과 가까운 기업, <em>피플라이프</em></p>
            </h3>
            <p class="desc_section">공중파 TV CF, 케이블 광고와 더불어 유튜브, SNS등<br> 다양한 채널을 통해 피플라이프를 만나실 수 있습니다. </p>
            <a class="webzine_area link_download" href="https://api.peoplelife.co.kr/webzine/external/open/name/peoplelife_wz2102" title="웹진 PDF파일 다운받기" target="_blank" download>
                <div class="thumb">
                    <i class="bg1" style="background-image:url(https://api.peoplelife.co.kr/webzine/external/load/a2cc398a-db74-4d00-a67f-0175a0030cd9)"></i>
                    <i class="bg2" style="background-image:url(https://api.peoplelife.co.kr/webzine/external/load/a2cc398a-db74-4d00-a67f-0175a0030cd9)"></i>
                </div>
                <span class="tit">웹진 2020.02</span>
            </a>
            <ul class="list_video">
                <li class="item_video">
                    <button class="videoLayer" data-src="/video/tvcf/ceoclinic/2019_season2.mp4" data-title="CEO클리닉 TVC 광고 <위기극복>편" type="button" title="영상재생">
                        <div class="thumb">
                            <i class="ico_play"></i>
                            <img src="/img/common/contents/videoSwiper/tvcf/ceoclinic/2019_season2.jpg" alt="스크린샷">
                        </div>
                        <p class="tit">CEO클리닉 TVC 광고 &#60;위기극복&#62;편</p>
                    </button>
                </li>
                <li class="item_video">
                    <img src="/img/promotion/promotion/visual_video_thumb4.jpg" alt="보클 처방전">
                    <p class="tit">보클 처방전</p>
                </li>
                <li class="item_video">
                    <img src="/img/promotion/promotion/visual_video_thumb3.jpg" alt="피플라이프 다드림쇼">
                    <p class="tit">피플라이프 다드림쇼</p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 비디오 상단 -->
    <section id="videoTop" class="section">
        <div class="inner">
            <ul class="list_video">
                <li class="item_video" data-attr-id="id">
                    <button class="btn_video" type="button">
                        <i class="thumb">
                            <i class="ico_play"></i>
                            <img>
                        </i>
                        <span class="tit" data-text="title" data-clamp="1"></span>
                    </button>
                </li>
            </ul>
        </div>
    </section>
    <!-- //비디오 상단 -->

    <!-- 비디오 하단 -->
    <section id="videoBottom" class="section">
        <div class="inner">
            <ul class="list_video">
                <li class="item_video" data-attr-id="id">
                    <button class="btn_video" type="button">
                        <div class="thumb">
                            <i class="ico_play"></i>
                            <img>
                        </div>
                        <p class="tit" data-text="title" data-clamp="1"></p>
                    </button>
                </li>
            </ul>
            <div class="more_wrap">
                <button class="btn_more hidden" title="더보기" type="button"><img src="" data-images-path="/image/svg/plus02.svg" alt="썸네일"></button>
            </div>
        </div>
    </section>
    <!-- //비디오 하단 -->

    <section id="sns" class="section">
        <div class="inner">
            <h3 class="tit_section">SNS</h3>
            <p class="desc_section">끊임없이 고객과 소통하는 우리는<br>피플라이프입니다.</p>
            <p class="tag_section">#PEOPLELIFE</p>
            <ul class="list_sns">
                <li class="item_sns">
                    <img class="thumb" src="/img/promotion/promotion/sns_thumb1.jpg" alt="사진">
                    <div class="list_link">
                        <img class="bi" src="" data-images-path="/image/logo/peoplelife_basic.svg" alt="로고">
                        <a class="link_sns" href="https://www.youtube.com/channel/UCRbs93BBQZa2SZUufB4Jepg" target="_blank" title="유투브 바로가기"><img src="" data-images-path="/image/sns/youtube01.svg" alt=""></a>
                        <a class="link_sns" href="https://blog.naver.com/peoplelifepr" target="_blank" title="블로그 바로가기"><img src="/img/common/ico/blog02.svg" alt=""></a>
                    </div>
                    <p class="tag"><span>#금융솔루션</span><span>#혁신의 아이콘</span><span>#금융상품판매 전문 플랫폼</span></p>
                </li>
                <li class="item_sns">
                    <img class="thumb" src="/img/promotion/promotion/sns_thumb2.jpg" alt="사진">
                    <div class="list_link">
                        <img class="bi" src="" data-images-path="/image/logo/bohumclinic_extension01.svg" alt="로고">
                        <a class="link_sns" href="https://www.instagram.com/bohum_clinic" target="_blank" title="인스타그램 바로가기"><img src="" data-images-path="/image/sns/instagram01.svg" alt=""></a>
                        <a class="link_sns" href="https://www.youtube.com/channel/UCzcc4DNIpNngewyH0Lt1tpg" target="_blank" title="유투브 바로가기"><img src="" data-images-path="/image/sns/youtube01.svg" alt=""></a>
                        <a class="link_sns" href="https://blog.naver.com/bohum_clinic" target="_blank" title="블로그 바로가기"><img src="/img/common/ico/blog02.svg" alt=""></a>
                        <a class="link_sns" href="https://www.facebook.com/bohumclinic" target="_blank" title="페이스북 바로가기"><img src="" data-images-path="/image/sns/facebook01.svg" alt=""></a>
                    </div>
                    <p class="tag"><span>#보험을 바로잡다 </span><span>#보장분석</span><span>#보험리모델링</span></p>
                </li>
                <li class="item_sns">
                    <img class="thumb" src="/img/promotion/promotion/sns_thumb3.jpg" alt="사진">
                    <div class="list_link">
                        <img class="bi ceoclinic" src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="로고">
                        <a class="link_sns" href="https://www.youtube.com/channel/UCv127K0IeyDsPttFEw20blQ" target="_blank" title="유투브 바로가기"><img src="" data-images-path="/image/sns/youtube01.svg" alt=""></a>
                        <a class="link_sns" href="https://www.facebook.com/ceoclinickr/" target="_blank" title="페이스북 바로가기"><img src="" data-images-path="/image/sns/facebook01.svg" alt=""></a>
                    </div>
                    <p class="tag"><span>#중소기업</span><span>#위기극복 파트너</span><span>#리스크 관리 컨설팅</span></p>
                </li>
            </ul>
        </div>
        <div class="bg_circle"></div>
    </section>

    <!-- 2020년 브러셔 -->
    <brochure2020></brochure2020>    
    <!-- //2020년 브러셔 -->
</div>
<!-- //#container -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/promotion.scss';
</style>
