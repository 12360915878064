<template>
<!-- #container -->
<div id="container" role="main">

    <!-- 페이지명 -->
    <section id="pageName">
        <div class="inner">
            <h3 class="tit_section">인사이드</h3>
            <p class="desc_section">금융·세무·법무·노무 등에 관련된 새로운 정보를 전달합니다.</p>
            <div class="formNews formRow clearfix" data-type="news">
                <label class="select_wrap">
                    <select name="news" class="news">
                        <option value="">전체</option>
						<option value="법인컨설팅">법인컨설팅</option>
						<option value="웹진">웹진</option>
						<option value="자산관리">자산관리</option>
						<option value="증여상속">증여상속</option>
						<option value="보험설계">보험설계</option>
						<option value="재무설계">재무설계</option>
                    </select>
                    <i data-images-path="/image/common/icoSelect.png"></i>
                </label>
            </div>
        </div>
    </section>
    <!-- 페이지명 -->

    <!-- 전체보기 -->
    <section id="inside" class="section">
        <div class="inner">
            <ul class="list_inside">
                <li class="item_inside hidden">
                    <a class="link_inside" href="">
                        <div class="thumb">
                            <img src="" alt="썸네일">
                        </div>
                        <div class="box_txt">
                            <p class="cate" data-text="categoryName2"></p>
                            <p class="tit" data-text="title"></p>
                            <p class="desc" data-text="shortContent"></p>
                            <p class="date" data-text="writeDate"></p>
                        </div>
                    </a>
                </li>
            </ul>
            <div class="more_wrap">
                <button class="btn_more hidden" title="더보기" type="button"><img src="" data-images-path="/image/svg/plus02.svg" alt=""></button>
            </div>
        </div>
    </section>
</div>
<!-- //#container -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/inside.scss';
</style>
