<!-- videoSwiper - TVCF -->
<template>
    <section id="videoSwiper" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 TV CF</h3>
            <p class="desc_section">어려운 보험.. 알아듣게 설명해 줄 사람 없나? 만나! 보험클리닉</p>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <button data-src="/file/video/2024_tvcf2_kakao.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2024_tvcf2_kakao_590.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;카톡&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/file/video/2024_tvcf2_search.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2024_tvcf2_search_590.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;검색&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/file/video/2024_tvcf2_youtube.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2024_tvcf2_youtube_590.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;유튜브&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2023_season1_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2023_season1_1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;보험고민&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2023_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2023_season2_1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;실손&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2023_season3_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2023_season3_1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;일반&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2019_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2019_season2_1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;어쩌다 마주친&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2019_season2_2.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2019_season2_2.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 광고 &#60;여기서 내려요&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2020_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2020_season2_1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;나이스&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/tvcf/bohumclinic/2020_season2_2.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/tvcf/bohumclinic/2020_season2_2.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">TVC광고 &#60;자꾸만 보고싶네&#62;편</p>
                    </div>
                </div>
            </div>
            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_left01.svg" alt="뒤로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></button>
            </div>
        </div>
        <img class="logo_symbol" src="" data-images-path="/image/logo/bohumclinic_character.svg" alt="로고">
    </section>
</template>



<script>
export default {
    name: 'videoSwiper01',    
}
</script>



<style lang="scss">
    #videoSwiper {
        padding:95px 0;
        &::after {
            content:"";position:absolute;top:0px;left:0;right:0;z-index:-1;height:445px;background-color:#f8f8f8;;
        }
        .tit_section {
            position:absolute;top:0;left:660px;font-size:38px;font-weight:500;line-height:1em;
        }
        .desc_section {
            position:absolute;top:60px;left:660px;font-size:16px;color:#666666;line-height:1em;
        }
        .swiper-thumb {
            overflow:visible;float:left;width:590px;
            .swiper-slide {
                button {                    
                    @include videoLayer;
                    height:410px;
                }
                .tit {
                    padding-top:20px;font-size:24px;font-weight:500;text-align:center;letter-spacing:-0.05em;
                    .num {
                        position:absolute;top: 255px;left:-210px;font-family:'SCDream',sans-serif;font-size:169px;font-weight:500;color:#000;line-height:1em;letter-spacing:-0.05em;opacity:0.1;
                    }
                }
            }
        }
        .swiper-top-position {
            overflow:hidden;position:absolute;top:184px;left:662px;width:(350px * 7);height:320px;
            .swiper-top {
                position:absolute;top:0;left:-353px;width:(350px * 8);             
                .swiper-slide {
                    width:330px;
                    button {                    
                        @include videoLayer;
                        height:226px;
                        .thumb {
                            width:100%;height:100%;
                            .ico_play {
                                transform:translate(-50%, -50%) scale(0.7) !important;
                            }
                            img {
                                width:100%;height:auto;
                            }
                        }
                    }
                    .tit {
                        display:flex;align-items:center;position:relative;height:70px;margin-top:15px;
                        .num {
                            position:absolute;top:50%;left:0;z-index:-1;font-family:'SCDream',sans-serif;font-size:78px;font-weight:500;color:#f2f2f2;line-height:1em;letter-spacing:-0.05em;transform:translateY(-50%);
                        }
                    }
                }
            }
        }
        .swiper-nav {
            position:absolute;top:135px;left:650px;z-index:1;width:175px;
            .swiper-pagination {
                bottom:-10px;color:#797979;font-family:'Roboto',sans-serif;font-size:16px;user-select:none;
                .swiper-pagination-current {
                    color:#000;
                }
            }
            .swiper-button {
                display:flex;justify-content:center;align-items:center;z-index:11;width:45px;height:45px;border:1px solid #cdcdcd;border-radius:100%;background:#fff;
                svg {
                    width:16px;height:auto;
                }
            }
        }
    }
</style>