<template>
<!-- #container -->
<div id="container" role="main">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">#<span>피플라이프 정규직 EFA</span>로<br>커리어 업그레이드!</h3>
            <div class="desc_section">
                <p class="tit">EFA (Employed Financial Advisors) 란?</p>
                <p class="desc">보험클리닉 피플라이프에서 선보이는<br>고객에게 찾아가는 <strong>정규직 보험컨설팅 조직</strong></p>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- EFA요약 -->
    <section id="summary" class="section">
        <div class="inner">
            <div class="contents">
                <img class="card" :src="imgPath+'summary_callingcard.png'" alt="명함">
                <h3 class="tit_section">보험설계사 경력을 살려서<br> 오늘부터 <span>정규직</span> 되세요</h3>
                <div class="desc_section">
                    <p class="tit"><span>EFA<i></i></span> 정규직 보험상담 매니저</p>
                    <p class="desc">직업의 안정, 소득의 안정, 고소득 실현<br> <strong>피플라이프의 정규직 보험상담매니저 (EFA)</strong>가<br> 추구하는 <strong>3대 비전</strong>입니다.<br> 안정성과 전문성을 갖춘 <strong>피플라이프 EFA</strong>에<br> 지금 바로 도전하세요. </p>
                </div>
                <div class="desc_efa">
                    <p class="efa_tit">
                        <span><b>E</b>mployed</span>
                        <span><b>F</b>inancial</span>
                        <span><b>A</b>dvisor</span>
                    </p>
                    <p class="efa_desc">
                        보험클리닉 피플라이프에서<br>
                        <b>국내 최초</b>로 선보이는<br>
                        고객에게 찾아가는<br>
                        <b>정규직 보험컨설팅 조직</b>
                    </p>
                    <em>※ 보험업 경력자 우대</em>
                </div>
            </div>
        </div>
        <i class="object object1"></i>
        <i class="object object2"></i>
        <i class="object object3"></i>
    </section>
    <!-- //EFA요약 -->

    <!-- 차별화-->
    <section id="special" class="section">
        <div class="inner">
            <h3 class="tit_section"><mark>EFA 상담매니저만의 차별화 3가지</mark></h3>
            <ul class="list_special">
                <li class="item_special">
                    <p class="tit">직업의 안정</p>
                    <p class="desc">정규직으로 4대보험 및<br>복리후생 제공</p>
                    <img class="ico" :src="imgPath+'special_item_ico1.png'" alt="">
                </li>
                <li class="item_special">
                    <p class="tit">소득의 안정</p>
                    <p class="desc">월 250만원 기본급으로<br>안정적인 소득보장</p>
                    <img class="ico" :src="imgPath+'special_item_ico2.png'" alt="">
                </li>
                <li class="item_special">
                    <p class="tit">고소득 실현</p>
                    <p class="desc">성과에 따른 인센티브로<br>고소득 가능</p>
                    <img class="ico" :src="imgPath+'special_item_ico3.png'" alt="">
                </li>
            </ul>
        </div>
    </section>
    <!-- //차별화 -->

    <section id="talkShow" class="section">
        <div class="inner">
            <button class="videoLayer" data-src="https://www.youtube.com/embed/Z9QQbv17mBc" data-title="보험설계사를 정규직으로? ㅣ 이번에는 EFA다" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="https://img.youtube.com/vi/Z9QQbv17mBc/maxresdefault.jpg" alt="스크린샷">
            </button>
            <p class="headline_section">Employed Financial Advisors</p>
            <h3 class="tit_section"><mark>보험설계사를 정규직으로?</mark><br><mark>이번에는 EFA다!</mark></h3>
            <p class="desc_section">피플라이프 EFA는 <strong>회사에 소속된 근로자 신분으로</strong><br> <strong>4대보험이 적용되고, 퇴직금을 별도로 받아</strong> 위촉직과는<br> 차별화를 두고있습니다.</p>
            <button class="btn_videoLayer" type="button"><img class="bi" :src="imgPath+'talkShow_logo.png'" alt="강과장의 보험토크쇼">영상보기<i class="ico"><img src="" data-images-path="/image/svg/play01.svg" alt="플레이"></i></button>
        </div>
    </section>

    <!-- 급여체계 -->
    <section id="salary" class="section">
        <div class="inner">
            <h3 class="tit_section"><mark>정규직 보험클리닉(EFA) 상담매니저 급여체계</mark></h3>
            <ul class="list_salary">
                <li class="item_salary">
                    <p class="tit">기본급</p>
                    <p class="desc">연봉 3,000만원<br>(월 250만원)</p>
                </li>
                <li class="item_salary">
                    <p class="tit">성과인센티브</p>
                    <p class="desc">생·손보 全 상품<br>성과인센티브<br>* 자세한 내용 별도문의</p>
                </li>
                <li class="item_salary">
                    <p class="tit">활동관련<br>Promotion</p>
                    <p class="desc">환산P’ 달성 규모에 따라<br>차등지급<br> * 자세한 내용 별도문의</p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //급여체계 -->

    <!-- 채용절차 -->
    <section id="process" class="section">
        <div class="inner">
            <h3 class="tit_section">정규직 보험클리닉(EFA) 상담매니저 채용절차</h3>
            <p class="desc_section">서류전형부터 면접까지! 보험클리닉 입사에 대한 모든 것</p>
            <ol class="list_process">
                <li class="item_process">
                    <p class="tit">서류전형</p>
                    <p class="cmt">* 서류 통과 시 개별 통보됩니다.</p>
                </li>
                <li class="item_process">
                    <p class="tit">1차 실무진 면접</p>
                </li>
                <li class="item_process">
                    <p class="tit">2차 임원면접</p>
                </li>
                <li class="item_process">
                    <p class="tit">최종합격</p>
                </li>
            </ol>
            <div class="dim">
                <!-- 모집부분 및 채용규모 -->
                <div class="table mb-60">
                    <table summary="직군별 채용안내에 대한 내용을 확인 할 수 있습니다.">
                        <caption>모집부분 및 채용규모</caption>
                        <colgroup>
                            <col style="width:15%;">
                            <col style="width:*;">
                            <col style="width:15%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>모집분야</th>
                                <th>주요업무</th>
                                <th>채용규모</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="align-center">정규직<br>보험클리닉(EFA)<br>상담매니저</th>
                                <td>
                                    ·보험비교분석 시스템을 활용한 보험 분석 및 대안 제시<br>
                                    ·고객응대 및 고객상담을 통한 영업
                                </td>
                                <td class="align-center">00명</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 전형별 일정 -->
                <div class="table mb-60">
                    <table>
                        <caption>전형별 일정</caption>
                        <colgroup>
                            <col style="width:20%;">
                            <col style="width:*;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>주요내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>서류전형</th>
                                <td>상시변경</td>
                            </tr>
                            <tr>
                                <th>면접일정</th>
                                <td>상시변경</td>
                            </tr>
                            <tr>
                                <th>인턴과정</th>
                                <td>상시변경</td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="cmt_table">※ 문의처 : 카카오톡 오픈채팅 – 1:1 채팅에서 ‘보험클리닉’을 검색하세요.</p>
                    <!-- <div class="recruit_close"><img src="/img/recruit/bohum/pc_recruit_end.png" alt="채용마감"></div> -->
                </div>
                <!-- 지원방법 -->
                <div class="table mb-60">
                    <table>
                        <caption>지원방법</caption>
                        <tbody>
                            <tr>
                                <td class="align-center">
                                    <p class="mb-10"><strong>사람인 즉시지원 또는 자사이력서 작성 후 이메일 제출 → <a href="mailto:bohumclinic@peoplelife.co.kr">bohumclinic@peoplelife.co.kr</a></strong></p>
                                    <p>※ 문의처 : 카카오톡 오픈채팅 – 1:1 채팅에서 ‘보험클리닉’을 검색하세요.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 고용형태 및 근무조건 -->
                <div class="table mb-60">
                    <table>
                        <caption>고용형태 및 근무조건</caption>
                        <colgroup>
                            <col style="width:20%;">
                            <col style="width:*;">
                            <col style="width:*;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>주요내용</th>
                                <th>비고</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>고용형태</th>
                                <td>
                                    <ul>
                                        <li>학습형 인턴과정 1개월</li>
                                        <li>인턴과정 수료 후 정규직 전환(수습평가 기간 6개월) </li>
                                    </ul>
                                </td>
                                <td> 인별평가 </td>
                            </tr>
                            <tr>
                                <th>근무시간</th>
                                <td>
                                    <ul>
                                        <li>주 5일, 일 8시간, 로테이션 근무 (주말근무 포함)</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>- 일요일 휴무</li>
                                        <li>- 주말근무 시 평일 대체휴무 부여</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>연봉</th>
                                <td>
                                    <ul>
                                        <li>연 3,000만원 기본급 (4대보험) + 인센티브</li>
                                    </ul>
                                </td>
                                <td> 인턴기간 <span class="font">中</span><br> 100만(세전) 지급 </td>
                            </tr>
                            <tr>
                                <th>채용지역</th>
                                <td>
                                    <ul>
                                    <li>전국</li>
                                    </ul>
                                </td>
                                <td class="new">
                                    <!-- * 우대지역 : 부산
                                    <ol>
                                        <li>서울(00명) : 전 지역</li>
                                        <li>경기북부(0명) : 포천, 의정부</li>
                                        <li>경기남부(0명) : 수원, 용인, 평택</li>
                                        <li>인천(0명)</li>
                                    </ol> -->
                                    -
                                </td>
                            </tr>
                            <tr>
                                <th>활동지원</th>
                                <td colspan="2">
                                    <ul>
                                    <li>매월 고객 상담 DB제공</li>
                                    <li>활동비 제공</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 지원자격 및 우대사항 -->
                <div class="table mb-60">
                    <table>
                        <caption>지원자격 및 우대사항</caption>
                        <colgroup>
                            <col style="width:20%;">
                            <col style="width:*;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>주요내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>지원자격</th>
                                <td>
                                    <dl class="list_bullet">
                                        <dd>초대졸 이상 또는 졸업예정자</dd>
                                        <dd>학습형 인턴과정 참여 가능자</dd>
                                    </dl>
                                </td>
                            </tr>
                            <tr>
                                <th>우대사항</th>
                                <td>
                                    <dl class="list_bullet">
                                        <dd>DB영업 有경험자</dd>
                                        <dd>생명보험, 손해보험, 변액보험판매자격 보유자 (인턴기간 중 취득 가능)</dd>
                                        <dd>금융업계 1년 이상 종사자 <span class="color-ff0000">(보험설계사 우대)</span></dd>
                                        <dd>AFPK, CFP등 금융자격증 보유자</dd>
                                        <dd>여성, 장교 / 부사관 출신</dd>
                                    </dl>
                                </td>
                            </tr>
                            <tr>
                                <th>유의사항</th>
                                <td>
                                    <dl class="list_bullet">
                                        <dt>[ 채용제한 ]</dt>
                                        <dd>신용등급 8등급 이하(점수 534점)</dd>
                                        <dd>보험회사 3년내 이직횟수 3회 이상자</dd>
                                        <dd>보증보험 가입 불가자</dd>
                                        <dd>지원서 내용이 사실과 다르거나 문서로 증빙이 불가능할 경우</dd>
                                    </dl>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 지원방법 -->
                <div class="table">
                    <table>
                        <caption>접수기간</caption>
                        <tbody>
                            <tr>
                                <td class="align-center">
                                    <p class="txt_dday">상시채용</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="recruit_close"><img src="/img/recruit/bohum/pc_recruit_end.png" alt="채용마감"></div> -->
                </div>
            </div>
        </div><!--// .dim-->
    </section>
    <!-- //채용절차 -->

    <!-- 방문상담신청 -->
    <section id="applyForm"  class="section" data-recruit-state="deadline">  <!-- 종료시 data-recruit-state="deadline" -->
        <div class="inner">
            <h3 class="tit_section"><mark>정규직 보험클리닉(EFA) 입사 지원하기</mark></h3>
            <li class="row row_carrer_life row_css" data-check="false" data-check-pass="true" data-inbound-prop="lifeCareerYn" data-inbound-value="Y" data-check-comment="생보경력유무를 선택하세요">
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_carrer_damage row_css" data-check="false" data-check-pass="true" data-inbound-prop="damageCareerYn" data-inbound-value="Y" data-check-comment="손보경력유무를 선택하세요">
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_file row_css" data-check="false" data-check-pass="true" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="loadForm"></div>
            <div class="box_info">
                <p class="tit">정규직 보험클리닉(EFA) <strong>입사지원서 다운받기</strong></p>
                <p class="desc">입사지원서를 작성하신 후 제출해주시기 바랍니다.<br><span>※ 다른 양식의 입사지원서로도 지원 가능합니다. (잡코리아, 사람인 이력서도 제출 가능)</span></p>
                <a class="link_download" href="" data-images-path="/data/doc/resume.docx" download><span>입사지원서 다운로드</span></a>
            </div>
        </div>
    </section>
    <!-- //방문상담신청 -->

    <!-- 취업설명회
    <section id="jobfair" class="section">
        <div class="inner">
            <h3 class="tit_section"><p class="header"><strong>JOB</strong> FAIR</p>EFA 취업설명회에 초대합니다.</h3>
            <ul class="list_jobfair">
                <li class="item_jobfair">
                    <p class="tit">일 시</p>
                    <p class="desc">5월 13일(수) 14:00 ~ 16:00</p>
                </li>
                <li class="item_jobfair">
                    <p class="tit">장 소</p>
                    <p class="desc">쉐라톤 서울 팔래스 강남 지하1층 다이너스티홀<br>(서울시 서초구 사평대로 160)</p>
                </li>
                <li class="item_jobfair">
                    <p class="tit">참가신청</p>
                    <p class="desc">02-2251-6862</p>
                </li>
            </ul>
            <div class="bg">
                <img class="model" src="/img/recruit/efa/jobfair_model1.png" alt="남성">
                <img class="model" src="/img/recruit/efa/jobfair_model2.png" alt="여성">
                <img class="model" src="/img/recruit/efa/jobfair_model3.png" alt="남성">
                <img class="model" src="/img/recruit/efa/jobfair_model4.png" alt="여성">
                <img class="model" src="/img/recruit/efa/jobfair_model5.png" alt="남성">
            </div>
            <img class="stamp" src="/img/recruit/efa/jobfair_stamp.png" alt="도장">
        </div>
    </section>
    //취업설명회 -->

    <!-- 채용문의 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">고용을 넘어 안정적인 소득을 원하신다면?</p>
            <h3 class="tit_section">정규직 보험상담매니저에 도전하세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <div class="box_about">
                <p class="tit">채용문의</p>
                <p class="tel">02-2085-6737</p>
                <p class="mail">bohumclinic@peoplelife.co.kr</p>
                <p class="cmt">평일 09:00~18:30 근무시간 외 E-mail 문의</p>
            </div>
        </div>
    </section>
    <!-- //채용문의 -->


    <!-- <aside id="banner_recruitEmail">
        <a class="link_more" href="http://asq.kr/7fUZxyrTwjml" target="_blank">
            <img src="/img/recruit/bohum/banner_recruit_pc_2109_2.png?v=2109" alt="보험클리닉 정규직 상담매니저 온라인 라이브 채용설명회 2021.09.13(월) 14:00">
        </a>
    </aside> -->

    <!-- 입사문의 바로가기 floating -->
    <aside id="banner_apply" class="hidden">
        <div class="inner">
            <button class="btn_apply anchor" data-target="#applyForm" data-recruit-state="" type="button"> <!-- 종료시 data-recruit-state="deadline" -->
                <span class="screen_out">입사문의 바로가기</span>
            </button>
        </div>
    </aside>
    <!-- //입사문의 바로가기 floating -->
</div>
<!-- //#container -->
</template>

<style lang="scss" scoped>
    @import '@/assets/scss/recruit/recruitefa.scss';
</style>

<script>
    export default {
        data(){
            return {
                imgPath: '/img/recruit/efa/'
            }
        }
    }
</script>