<!-- videoSwiper - 인포모셜 광고 -->
<template>
    <section id="videoSwiper" class="section">
        <div class="inner">
            <h3 class="tit_section">인포머셜 광고</h3>
            <p class="desc_section">보장, 어디서 새지 않도록! 꽉 잡아드립니다.</p>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <button data-src="/video/drama/type2/ep1.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/drama/type2_ep1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">#보험의 세계<br> &#60;갱신마다 오르는 보험료+줄줄 새는 보험료&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/drama/type2/ep2.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/drama/type2_ep2.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">#보험의 세계<br> &#60;보험을 시작할때+부족한 보장&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/drama/type1/ep2.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/drama/type1_ep1.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">#보클TV 끝장드라마<br> &#60;이게답니까?&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/drama/type1/ep3.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/drama/type1_ep2.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">#보클TV 끝장드라마<br> 광고 &#60;어디서 새고 있어?&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/drama/type1/ep3.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/drama/type1_ep3.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">#보클TV 끝장드라마<br> 광고 &#60;오르긴 뭐가 올라!&#62;편</p>
                    </div>
                    <div class="swiper-slide">
                        <button data-src="/video/drama/type1/ep4.mp4" data-title="" data-type="" type="button" title="영상재생">
                            <img class="thumb" src="/img/common/contents/videoSwiper/drama/type1_ep4.jpg" alt="스크린샷">
                        </button>
                        <p class="tit">#보클TV 끝장드라마<br> &#60;준비안된게자랑이야?&#62;편</p>
                    </div>
                </div>
            </div>
            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_left01.svg" alt="뒤로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></button>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'videoSwiper02',    
}
</script>



<style lang="scss">
    /* videoSwiper01.vue 에서 수정하세요 */
</style>