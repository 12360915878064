<template>
    <div id="container">
        <section id="map_wrap" class="section">
            <aside class="aside">
                <h3>사업단찾기</h3>
                <div class="tab-menu">
                    <button type="button" class="all on" value="" data-name="전체">전체</button>
                    <button type="button" class="clinic" value="4" data-name="보험클리닉">보험클리닉</button>
                    <button type="button" class="tm" value="5" data-name="TFA">TM센터</button>
                    <button type="button" class="indi" value="2" data-name="개인">개인사업단</button>
                    <button type="button" class="corp" value="1" data-name="법인">법인사업단</button>
                    <!-- <button type="button" class="multi" value="3" data-name="멀티">찾아가는<br> 보험클리닉</button> -->
                </div>
                <form action="javascript:;" class="search-box">
                    <select name="" id="">
                        <option data-value="shortName" data-text="shortName"></option>
                    </select>
                    <input type="text" placeholder="지역 및 사업단명 입력" id="keyword">
                    <button type="submit" id="search">검색</button>
                    <p class="txt">
                        <strong class="area">전체</strong> 지역 총 <strong class="num">0</strong>건 사업단이 검색되었습니다.
                    </p>
                </form>
                <div class="tab-wrap">
                    <ul class="tab-contents">
                        <li data-attr-id="deptId" data-attr-gubun="publicGubun">
                            <div class="info">
                                <strong class="marker" data-text="publicName"></strong>
                                <address>
                                    <b class="first" data-text="publicAddress1"></b>
                                    <b class="second" data-text="publicAddress2"></b>
                                </address>
                                <span data-text="publicPhone"></span>
                                <a href="javascript:;" target="_blank" class="find_shop">길찾기</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="no-results">
                    <img src="/img/intro/branch/ico_alert.png" alt=""><br><br>
                    <span>검색된 정보가 없습니다.</span>
                </div>
            </aside>
            <div id="map">
                <div class="shop_info">
                    <div class="content">
                        <strong class="shop_name"></strong>
                        <span class="shop_address"></span>
                        <span class="shop_phone"></span>
                        <a href="javascript:;" target="_blank" class="find_shop">길찾기</a>
                    </div>
                    <button type="button" class="close">닫기</button>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped lang="scss">
@import '@/assets/scss/intro/branch.scss';
</style>
<script>
export default {
    
}
</script>