<!-- 업계 no.1 피플라이프 보험컨설팅 -->
<template>
    <section id="bohumConsulting" class="section">
        <div class="inner">
            <h3 class="tit_section">믿을 수 있는 피플라이프 보험컨설팅</h3>
            <ul class="list_bohum">
                <li class="item_bohum">
                    <img class="thumb" :src="imgPath+'thumb1.jpg'">
                    <p class="tit">회사설립</p>
                    <p class="num"><span>2003</span></p>
                </li>
                <li class="item_bohum">
                    <img class="thumb" :src="imgPath+'thumb2.jpg'">
                    <p class="tit">보험 전문가<span class="year">(2023년 6월 설계사수 기준)</span></p>
                    <p class="num"><span>4,050</span>명</p>
                </li>
                <li class="item_bohum">
                    <img class="thumb" :src="imgPath+'thumb3.jpg'">
                    <p class="tit">사업단 수<span class="year">(2023년 6월 지점현황 기준)</span></p>
                    <p class="num"><span>135</span>개</p>
                </li>
            </ul>
            <p class="source">(출처:2023 상반기 생명보험협회 통합공시자료)</p>
        </div>
    </section>
</template>



<script>
export default {
    name: 'bohumConsulting',
    data() {
        return {
            imgPath: '/img/common/contents/bohumConsulting/'
        }
    }
}
</script>



<style lang="scss">
    $imgPath : "/img/common/contents/bohumConsulting/";

    #bohumConsulting {
        height:520px;padding-top:60px;background-image:url(#{$imgPath}bg.jpg);background-size:cover;
        .desc_section {
            margin-bottom:15px;font-size:24px;color:#fff;text-align:center;line-height:1em;
        }
        .tit_section {
            margin-bottom:35px;font-size:38px;font-weight:500;color:#fff;text-align:center;line-height:1em;
        }
        .source {
            position:absolute;bottom:30px;left:50%;font-size:14px;color:#fff;transform:translateX(-50%);
        }
    }

    #bohumConsulting {
        .list_bohum {
            width:925px;margin:0 auto;
            .item_bohum {
                float:left;margin-left:170px;opacity:0;transform:translateY(20px);transition:none;
                &:first-child {
                    margin-left:0;
                }
                .thumb {
                    display:block;margin-bottom:15px;border-radius:100%;
                }
                .tit {
                    height:45px;color:#fff;text-align:center;
                    .year {
                        display:block;font-size:12px;
                    }
                }
                .num {
                    font-size:16px;color:#fff;text-align:center;
                    span {
                        font-family:"MongolianBait";font-size:48px;
                    }
                }
            }
        }
    }

    #bohumConsulting {
        .item_bohum {
            &.appear {
                @for $i from 1 through 3 {
                    &:nth-child(#{$i}) {
                        opacity:1;transform:translateY(0);transition:all 0.6s #{$i * 0.2}s ease-in-out;
                    }
                }
            }
        }
    }
</style>