<template>
	<div id="container">
		<section id="visual" class="section">
			<h3>전문가가 제안하는 분명하고 혁신적인 전략</h3>
		</section>
		<section id="network_list" class="section">
			<p class="desc">
				피플라이프의 자산관리 및 경영효율화 지원 네트워크 전문가 그룹의<br>
				<strong>원스톱 토탈 솔루션</strong>은 때로는 독립적으로, 때로는 다 같이
				팀을 구성하여<br>
				개인자산가에서 중소 &middot; 중견기업까지 솔루션을 구현합니다.
			</p>
			<div class="network_tab_menu list1">
				<div class="inner">
					<div class="tab_menu_list">
						<ul>
							<li data-type="세무" data-id="list1" class="on">
								<button type="button">
									<span>세무</span><em>절세 절략<br>연구개발</em>
								</button>
							</li>
							<li data-type="법무" data-id="list2">
								<button type="button">
									<span>법무</span><em>기업운영<br>법률자문서비스</em>
								</button>
							</li>
							<li data-type="노무" data-id="list3">
								<button type="button">
									<span>노무</span><em>기업과 종업원의 관계,<br>노무 컨설팅</em>
								</button>
							</li>
							<li data-type="부동산" data-id="list4">
								<button type="button">
									<span>부동산</span><em>종합 부동산<br>컨설팅</em>
								</button>
							</li>
							<li data-type="기업금융" data-id="list5">
								<button type="button">
									<span>기업금융</span><em>기업금융, 상장 관련<br>컨설팅 지원</em>
								</button>
							</li>
						</ul>
					</div>
					<div class="tab_menu_contents">
						<div id="list1" data-type="세무" class="list_contents active">
							<div class="sub_txt_contents">
								<h4 class="title">세무법인 세종TSI</h4>
								<p class="txt">
									세종TSI는 피플라이프와 제휴된 업체로 법인 및 고액자산가들에 대한<br>
									경영&자산관리 컨설팅을 제공하고 있는 세무법인 입니다.
								</p>
							</div>
							<div class="sub_tab_contents">
								<div class="sub_tab">
									<div class="service">
										<div>
											<h5 class="title">주요서비스</h5>
											<div class="service_tab">
												<ul>
													<li data-id="list1_1" class="on">
														<button type="button">기업경영 영역</button>
													</li>
													<li data-id="list1_2">
														<button type="button">오너CEO자산관리 영역</button>
													</li>
												</ul>
											</div>
											<div id="list1_1" class="service_contents active">
												<ul>
													<li>이익금 관리</li>
													<li>세무관련 제도정비</li>
													<li>핵심인재관리 및 보상</li>
													<li>모의 세무진단</li>
													<li>외부감사 전략 수립</li>
												</ul>
												<ul>
													<li>기업진단</li>
													<li>합병 및 분할 전략 수립</li>
													<li>
														법인에 대한 EXIT 플랜<br>(적정보수,
														적정배당정책,<br>
														자기주식, 청산 등)
													</li>
												</ul>
												<ul>
													<li>차명주식 회수</li>
													<li>가지급금, 가수금 정리</li>
													<li>가업승계</li>
													<li>개인사업법인전환</li>
												</ul>
											</div>
											<div id="list1_2" class="service_contents">
												<ul>
													<li>상속증여 플랜</li>
													<li>금융자산 플랜</li>
													<li>부동산관련 세금</li>
													<li>차명주식 회수</li>
												</ul>
												<ul>
													<li>
														법인에 대한 EXIT 플랜<br>(적정보수,
														적정배당정책,<br>
														자기주식, 청산 등)
													</li>
												</ul>
												<ul>
													<li>가지급금, 가수금 정리</li>
													<li>가업승계</li>
													<li>개인사업법인전환</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="list2" data-type="법무" class="list_contents">
							<div class="sub_txt_contents">
								<h4 class="title">가이우스 법률 사무소</h4>
								<p class="txt">
									가이우스 법률사무소는 중소기업을 위한 법률상의 특례제도를
									구현시키고,<br>
									상법의 회사법편에서 규율하고 있는 회사 경영의 원칙을
									중소기업에 정착시키기 위한 자문과 소송에 특화되어 있습니다.
								</p>
							</div>
							<div class="sub_tab_contents">
								<div class="sub_tab">
									<div class="service">
										<div>
											<h5 class="title">주요서비스</h5>
											<div id="list2_1" class="service_contents active">
												<ul>
													<li>
														법률상 특례제도 구현을 위한<br>
														중소기업 육성 및 지원법률 연구
													</li>
													<li>
														상법의 회사법편에 따른<br>
														적법한 회사경영에 대한 자료
													</li>
												</ul>
												<ul>
													<li>
														동업자나 직원의 배신으로 인한<br>
														경영 리스크 대비방안
													</li>
													<li>
														기업의 Know How<br>
														산업재산권화 지원
													</li>
												</ul>
												<ul>
													<li>
														중소기업 경영을 위한<br>
														계약서 작성 및 계약서 검토
													</li>
													<li>
														중소기업의 골칫거리<br>
														차명주식의 실명전환
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="list3" data-type="노무" class="list_contents">
							<div class="sub_txt_contents">
								<h4 class="title">노무법인 익선, 서현</h4>
								<p class="txt">
									좋은 직원을 고용하여 유지하는 것은 중소기업 경쟁력에 있어서
									빼놓을 수 없는 영역입니다.<br>
									간단치 않은 중소기업의 인사와 노무문제에 대한 풍부한
									Solution을 제공해 드립니다.
								</p>
							</div>
							<div class="sub_tab_contents">
								<div class="sub_tab">
									<div class="service">
										<div>
											<h5 class="title">주요서비스</h5>
											<div class="service_tab">
												<ul>
													<li data-id="list3_1" class="on">
														<button type="button">노무 법률 자문</button>
													</li>
													<li data-id="list3_2">
														<button type="button">
															<span>급여아웃소싱 / 4대보험관리</span>
														</button>
													</li>
												</ul>
											</div>
											<div id="list3_1" class="service_contents active">
												<ul>
													<li>
														근로시간, 임금, 근태, 근로관계 종료,<br>
														퇴직금 등 각종 개별적 근로관계에<br>
														관한 법률 자문
													</li>
													<li>
														산업재해 및 산업안전에 관한<br>
														법률문제, 외국인 근로자와<br>
														관련된 문제 자문
													</li>
												</ul>
												<ul>
													<li>
														노조설립, 단체협상, 쟁의와 개별<br>
														요구안에 대한 법률적 평가와<br>
														대응 등 노사문제 법률 자문
													</li>
													<li>
														연봉제 평가제도, 성과급제 등,<br>
														인사제도 운영실태에 대한 자문
													</li>
												</ul>
												<ul>
													<li>
														파견, 도급, 기간제 근로자와의 <br>법률문제,
														국민연금, 고용, 산재,<br>
														건강보험업무 자문
													</li>
													<li>쟁의행위 발생시의 대응 자문</li>
												</ul>
											</div>
											<div id="list3_2" class="service_contents">
												<ul>
													<li>
														급여체계와 개인별 급여관리,<br>
														퇴직자관리
													</li>
													<li>산업재해 신청 및 급여청구</li>
												</ul>
												<ul>
													<li>
														4대보험 취득 및 상실업무,<br>
														근로계약서, 취업규칙 정비
													</li>
													<li>
														해고, 인사청구, 구제신청 및 대응,<br>
														산재 고용보험, 임금체불, 체당금
													</li>
												</ul>
												<ul>
													<li>노동사건 대리 및 교육서비스</li>
													<li>
														성희롱 예방교육 등 각종 법정<br>
														필수 교육 서비스
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="list4" data-type="부동산" class="list_contents">
							<div class="sub_txt_contents">
								<h4 class="title">부동산 전문</h4>
								<p class="txt">
									부동산과 관련된 고객의 수요를 전문가의 시각으로 분석하고<br>
									풍부한 네트워크를 활용하여 최적의 Solution을 제공해
									드리겠습니다.
								</p>
							</div>
							<div class="sub_tab_contents">
								<div class="sub_tab">
									<div class="service">
										<div>
											<h5 class="title">주요서비스</h5>
											<div id="list4_1" class="service_contents active">
												<ul>
													<li>
														부동산의 매입, 매각, 개발,<br>
														리모델링과 관련된 부동산 최유효 활용
													</li>
													<li>
														부동산 매각 관련 적정 Value,<br>
														매각 마케팅 계획, 홍보전략 등 자문
													</li>
													<li>부동산 매매, 임대 관련 중개</li>
												</ul>
												<ul>
													<li>
														부동산 법인전환의 검토와<br>
														실행 서비스
													</li>
													<li>
														부동산 감정평과, 법무등기,<br>개발검토, 분양대행,
														부동산 PM
													</li>
												</ul>
												<ul>
													<li>
														부동산 매입 관련 적정 Value,<br>자금계획, 소유구조
														등 자문
													</li>
													<li>특수관계자간 부동산 활용</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="list5" data-type="기업금융" class="list_contents">
							<div class="sub_txt_contents">
								<h4 class="title">IPO · M&amp;A 전문 머스트윌</h4>
								<p class="txt">
									중소 · 벤처기업을 위한 기업금융(Corporate Financing) 자문을
									전문으로 합니다.<br>
								</p>
							</div>
							<div class="sub_tab_contents">
								<div class="sub_tab">
									<div class="service">
										<div>
											<h5 class="title">주요서비스</h5>
											<div class="service_tab">
												<ul>
													<li data-id="list5_1" class="on">
														<button type="button">IPO 컨설팅</button>
													</li>
													<li data-id="list5_2">
														<button type="button">M&amp;A 컨설팅</button>
													</li>
													<li data-id="list5_3">
														<button type="button">자금조달 컨설팅</button>
													</li>
												</ul>
											</div>
											<div id="list5_1" class="service_contents active">
												<ul>
													<li>
														코스닥 상장심사 통과 가능성에 <br>대한 분석 및
														미비점에 대한 해결책 제시
													</li>
												</ul>
												<ul>
													<li>
														사전준비 단계에서 직면하는<br>
														이슈에 대한 해결책 및 일정을<br>
														포함하여 IPO 로드맵 작성
													</li>
												</ul>
												<ul>
													<li>
														주관증권사 선정 후 상장<br>
														본 단계에서 발생하는 <br>이슈 및 대응책에 대한
														Cross-check
													</li>
												</ul>
											</div>
											<div id="list5_2" class="service_contents">
												<ul>
													<li>
														M&amp;A 관련 주체들인 증권회사,<br>
														회계법인 PEF, 벤처캐피탈 등 과의 <br>협업을 통해
														DEAL 성공률을 극대화
													</li>
												</ul>
												<ul>
													<li>
														M&amp;A 관련 협상 자문 및 <br>계약서 등 작성 자문
													</li>
												</ul>
												<ul>
													<li>
														M&amp;A 관련 대상에 대한 타당성<br>
														평가 및 자문
													</li>
												</ul>
											</div>
											<div id="list5_3" class="service_contents">
												<ul>
													<li>
														투자유치를 위한 제반 여건 충족<br>
														여부에 대한 분석과 해결책 제시
													</li>
												</ul>
												<ul>
													<li>
														투자조건, 방식(RCPS, CB, BW 등)<br>
														및 자료준비 등을 포함한 실행전략 수립
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="member_list">
						<h4 class="title">구성원</h4>
						<ul>
							<li>
								<button type="button">
									<strong>
										<span class="name" data-text="name">이름</span>
										<em class="role" data-text="role"></em>
									</strong>
									<img src="" alt="프로필">
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<style scoped lang="scss">
	@import "@/assets/scss/intro/network.scss";
</style>
<script>
	export default {};
</script>