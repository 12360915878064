<!-- 2020년 브러셔 -->
<template>
    <section id="brochure2020" class="section">
        <div class="inner">
            <h3 class="tit_section">
                <p><strong>PEOPLELIFE</strong> Brochure</p>
                <p>금융유통플랫폼 <strong>피플라이프</strong></p>
            </h3>
            <p class="desc_section">금융정보의 비대칭을 해소할 수 있는 금융상품 판매 플랫폼이 피플라이프의 미래입니다.<br> 최적의 금융 상품을 추천 받고, 정확한 구매 경험을 할 수 있는 환경을 제공하는<br> 금융유통회사로의 비전을 위해 새로운 금융유통 플랫폼을 만들고 또 개선해 나가겠습니다.</p>
            <a class="link_download" href="" data-images-path="/pdf/peoplelife_brochure/2023.pdf" target="_blank" download><span>자세히 보기</span><img src="" data-images-path="/image/svg/plus01.svg" alt="다운로드"></a>
            <a class="link_book" href="" data-images-path="/pdf/peoplelife_brochure/2023.pdf" target="_blank" download>
                <img class="book" src="/img/common/contents/brochure2020/book.png" alt="책표지">
            </a>
        </div>
    </section>
</template>



<script>
import $ from 'jquery'

export default {
    name: 'brochure2020',
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){


        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }

}
</script>



<style lang="scss">
    #brochure2020 {
        height:410px;background-image:url(/img/common/contents/brochure2020/bg.jpg);background-size:cover;
        .tit_section {
            margin-bottom:20px;padding-top:70px;font-size:38px;
            p {
                &:nth-child(1) {
                    color:#7b8aad;
                }
                &:nth-child(2) {
                    color:#fff;
                    strong {
                        font-weight:500;
                    }
                }
            }
        }
        .desc_section {
            margin-bottom:40px;color:#fff;
        }
    }

    #brochure2020 .link_download {        
        display:inline-flex;align-items:center;position:relative;z-index:1;height:45px;padding:0 33px;border:1px solid #fff;transition:all 0.4s ease-in-out;
        &::after {
            content:"";position:absolute;top:0;bottom:0;left:0;right:100%;z-index:-1;background-color:#0f95d7;transition:all 0.4s ease-in-out;
        }
        span {
            display:inline-block;margin:0 10px 2px 0;color:#fff;line-height:1em;transition:all 0.6s linear;
        }
        svg {
            fill:#fff;width:16px;transition:all 0.6s linear;
        }
    }  

    #brochure2020.hover .link_download {        
        border-color:#0f95d7;
        &::after {
            right:0;
        }
        span {
            color:#fff;
        }
        svg {
            fill:#fff;
        }
    }

    #brochure2020 .link_book {
        position:absolute;top:50px;right:50px;
        &::after {
            /* content:"2020";position:absolute;bottom:55px;right:-30px;display:inline-block;font-size:25px;font-weight:bold;color:#fff;line-height:1.2em;white-space:pre;transform:rotate(90deg); */
        }
    }
</style>