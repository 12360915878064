<template>
    <div id="container">
        <section id="complete" class="indi section">
            <strong class="tit">
                <span>보험진단부터 리모델링, 비교 추천까지! </span>
            </strong>
            <div class="box">
                <img src="/img/brand/man.png" alt="">
                <p class="txt">
                    1:1 맞춤 상담신청이 완료되었습니다.<br>
                    기재해주신 번호로 <strong>1~2일 이내</strong>에 전화 드리겠습니다.<br><br>
                    감사합니다.
                </p>
            </div>
            <div class="btn-area">
                <a href="javascript:history.back();" class="btn">이전화면으로 이동</a>
            </div>
        </section>
    </div>
</template>
<style scoped lang="scss">
@import '@/assets/scss/common/complete.scss';
</style>
<script>
export default {
    
}
</script>